/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Application {
  CONTRACTS = "CONTRACTS",
  PROPOSALS = "PROPOSALS",
  REPORTS = "REPORTS",
}

export enum ContainerSortableColumn {
  CREATED_AT = "CREATED_AT",
  CURRENT_USER_ACTION_PENDING = "CURRENT_USER_ACTION_PENDING",
  DOCUMENTS_COUNT = "DOCUMENTS_COUNT",
  DOCUMENT_DIVISION_NAME = "DOCUMENT_DIVISION_NAME",
  END_DATE = "END_DATE",
  ID = "ID",
  LEGAL_FOLDER_ID = "LEGAL_FOLDER_ID",
  NAME = "NAME",
  NOTES = "NOTES",
  OWNER_NAME = "OWNER_NAME",
  PROJECT_ID = "PROJECT_ID",
  START_DATE = "START_DATE",
  STATUS = "STATUS",
  UPDATED_AT = "UPDATED_AT",
}

export enum ContainerStatus {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  EXPIRED = "EXPIRED",
}

export enum ContractProposalSortableColumn {
  ID = "ID",
  NAME = "NAME",
}

export enum CountrySortableColumn {
  CODE = "CODE",
  ID = "ID",
  NAME = "NAME",
}

export enum DivisionSortableColumn {
  ID = "ID",
  NAME = "NAME",
}

export enum DocumentFileType {
  COMMENT = "COMMENT",
  DOCUSIGN = "DOCUSIGN",
  SUPPORT_DOCUMENT = "SUPPORT_DOCUMENT",
  VERSION = "VERSION",
}

export enum DocumentIndemnity {
  INDEMNITYLITE = "INDEMNITYLITE",
  INDEMNITYWITHLIMITATIONOFLIABILITY = "INDEMNITYWITHLIMITATIONOFLIABILITY",
  LIMITIONOFLIABILITYONLYNOIND = "LIMITIONOFLIABILITYONLYNOIND",
  NONE = "NONE",
  REVISEDINDEMNITY = "REVISEDINDEMNITY",
  STANDARDINDEMNITY = "STANDARDINDEMNITY",
  TOBEDETERMINED = "TOBEDETERMINED",
}

export enum DocumentPartyReviewStatus {
  APPROVED = "APPROVED",
  DISAPPROVED = "DISAPPROVED",
  NONE = "NONE",
  PENDING = "PENDING",
  REQUIRED = "REQUIRED",
}

export enum DocumentSortableColumn {
  CONTAINER_NAME = "CONTAINER_NAME",
  CREATED_AT = "CREATED_AT",
  CURRENT_USER_ACTION_PENDING = "CURRENT_USER_ACTION_PENDING",
  DIVISION_ID = "DIVISION_ID",
  DIVISION_NAME = "DIVISION_NAME",
  DOCUMENT_TYPE_ID = "DOCUMENT_TYPE_ID",
  DOCUMENT_TYPE_NAME = "DOCUMENT_TYPE_NAME",
  END_DATE = "END_DATE",
  ID = "ID",
  LEGAL_FOLDER_NAME = "LEGAL_FOLDER_NAME",
  NAME = "NAME",
  OWNER_NAMES = "OWNER_NAMES",
  PENDING_REVIEWER_NAMES = "PENDING_REVIEWER_NAMES",
  START_DATE = "START_DATE",
  STATUS = "STATUS",
  STATUS_FOLDER = "STATUS_FOLDER",
  UPDATED_AT = "UPDATED_AT",
}

export enum DocumentStatus {
  APPROVED = "APPROVED",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  CREATED = "CREATED",
  DISAPPROVED = "DISAPPROVED",
  PENDING = "PENDING",
  TERMINATED = "TERMINATED",
}

export enum DocumentStatusFolder {
  APPROVED = "APPROVED",
  NEGOTIATING = "NEGOTIATING",
  SIGNED = "SIGNED",
}

export enum LegalFolderSortableColumn {
  CREATED_AT = "CREATED_AT",
  CURRENT_USER_ACTION_PENDING = "CURRENT_USER_ACTION_PENDING",
  ID = "ID",
  NAME = "NAME",
  PARTY_ID = "PARTY_ID",
  UPDATED_AT = "UPDATED_AT",
}

export enum MonetaryTransactionType {
  COST = "COST",
  REVENUE = "REVENUE",
}

export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

export enum PartyContactType {
  REGULAR = "REGULAR",
}

export enum PartySortableColumn {
  ADDRESS = "ADDRESS",
  ADDRESS2 = "ADDRESS2",
  ALIAS = "ALIAS",
  CITY = "CITY",
  CLIENT_CODE = "CLIENT_CODE",
  COUNTRY_ID = "COUNTRY_ID",
  CREATED_AT = "CREATED_AT",
  DIVISION_OWNERSHIP_NAME = "DIVISION_OWNERSHIP_NAME",
  FOCAL_POINT_USER_NAME = "FOCAL_POINT_USER_NAME",
  ID = "ID",
  IS_ACTIVE = "IS_ACTIVE",
  NAME = "NAME",
  PARTY_DESIGNATION_ID = "PARTY_DESIGNATION_ID",
  PARTY_TYPE = "PARTY_TYPE",
  STATE = "STATE",
  ZIP = "ZIP",
}

export enum PartyTypeEnum {
  CLIENT = "CLIENT",
  NONCLIENT = "NONCLIENT",
}

export enum ProjectSortableColumn {
  AS_OF_DATE = "AS_OF_DATE",
  CREATED_AT = "CREATED_AT",
  ID = "ID",
  IS_ACTIVE = "IS_ACTIVE",
  KEY = "KEY",
  NAME = "NAME",
  PREDECESSOR = "PREDECESSOR",
  UPDATED_AT = "UPDATED_AT",
}

export enum ProposalEntityType {
  OPPORTUNITY = "OPPORTUNITY",
  PROPOSAL = "PROPOSAL",
}

export enum UserSortableColumns {
  CREATED_AT = "CREATED_AT",
  DISCIPLINE_CATEGORY = "DISCIPLINE_CATEGORY",
  DIVISION = "DIVISION",
  EMAIL = "EMAIL",
  ID = "ID",
  IS_ACTIVE = "IS_ACTIVE",
  IS_ASSETS_USER = "IS_ASSETS_USER",
  NAME = "NAME",
  OID = "OID",
  UPDATED_AT = "UPDATED_AT",
}

export interface ContainerFilter {
  ids?: string[] | null;
  legalFolderIds?: string[] | null;
  onlyUnassignedToProjects?: boolean | null;
  projectIds?: string[] | null;
  projectKeyOrNameContains?: string | null;
  nameContains?: string | null;
  statuses?: ContainerStatus[] | null;
  ownerNameContains?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  onlyCurrentUserDocuments?: boolean | null;
  documentDivisionIds?: string[] | null;
}

export interface ContainerOwnerAddInput {
  userId: string;
}

export interface ContainerOwnerRemoveInput {
  userId: string;
}

export interface ContainerSort {
  column: ContainerSortableColumn;
  order: Order;
}

export interface ContractProposalFilter {
  entityType?: ProposalEntityType | null;
  ids?: string[] | null;
  nameContains?: string | null;
  partyIds?: string[] | null;
}

export interface ContractProposalSort {
  column: ContractProposalSortableColumn;
  order: Order;
}

export interface CountryFilter {
  codeOrNameContains?: string | null;
  ids?: string[] | null;
}

export interface CountrySort {
  column: CountrySortableColumn;
  order: Order;
}

export interface DivisionFilter {
  application?: Application | null;
}

export interface DivisionSort {
  column: DivisionSortableColumn;
  order: Order;
}

export interface DocumentDocusignCCUser {
  userId?: string | null;
  name?: string | null;
  email?: string | null;
}

export interface DocumentDocusignSigner {
  userId?: string | null;
  routingOrder?: number | null;
  note?: string | null;
  name?: string | null;
  email?: string | null;
  emailSubject?: string | null;
  emailBody?: string | null;
}

export interface DocumentFilter {
  ids?: string[] | null;
  nameContains?: string | null;
  statuses?: DocumentStatus[] | null;
  statusFolders?: DocumentStatusFolder[] | null;
  indemnities?: DocumentIndemnity[] | null;
  startDate?: any | null;
  startDateFrom?: any | null;
  startDateTo?: any | null;
  endDate?: any | null;
  endDateFrom?: any | null;
  endDateTo?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  documentTypeIds?: string[] | null;
  onlyCurrentUserDocuments?: boolean | null;
  partyNameContains?: string | null;
  onlyPartyApprovalPending?: boolean | null;
  legalFolderNameContains?: string | null;
  legalFolderIds?: string[] | null;
  containerNameContains?: string | null;
  containerIds?: string[] | null;
  containerStartDateFrom?: any | null;
  containerStartDateTo?: any | null;
  containerEndDateFrom?: any | null;
  containerEndDateTo?: any | null;
  divisionIds?: string[] | null;
  pendingReviewersNameContains?: string | null;
  ownersNameContains?: string | null;
  projectNameContains?: string | null;
  projectKeyContains?: string | null;
}

export interface DocumentMonetaryTransactionBreakdownAdd {
  value: number;
  startTimestamp: any;
}

export interface DocumentMonetaryTransactionBreakdownModify {
  monetaryTransactionBreakdownId: string;
  value?: number | null;
  startTimestamp?: any | null;
}

export interface DocumentMonetaryTransactionBreakdownRemove {
  monetaryTransactionBreakdownId: string;
}

export interface DocumentObserverAddInput {
  userId: string;
  order?: number | null;
  isEnabled?: boolean | null;
}

export interface DocumentObserverModifyInput {
  userId: string;
  order?: number | null;
  isEnabled?: boolean | null;
}

export interface DocumentOwnerAddInput {
  userId: string;
  order?: number | null;
  isEnabled?: boolean | null;
}

export interface DocumentOwnerModifyInput {
  userId: string;
  order?: number | null;
  isEnabled?: boolean | null;
}

export interface DocumentReviewerAddInput {
  userId: string;
  order?: number | null;
  isEnabled?: boolean | null;
}

export interface DocumentReviewerModifyInput {
  userId: string;
  order?: number | null;
  isEnabled?: boolean | null;
}

export interface DocumentSort {
  column: DocumentSortableColumn;
  order: Order;
}

export interface LegalFolderFilter {
  ids?: string[] | null;
  nameContains?: string | null;
  name?: string | null;
  partyIds?: string[] | null;
  partyIdsRequired?: boolean | null;
  nameOrContainerNameContains?: string | null;
  onlyCurrentUserDocuments?: boolean | null;
}

export interface LegalFolderOwnerAddInput {
  userId: string;
}

export interface LegalFolderOwnerRemoveInput {
  userId: string;
}

export interface LegalFolderSort {
  column: LegalFolderSortableColumn;
  order: Order;
}

export interface PartyContactFilterInput {
  withDeleted?: boolean | null;
  partyIds?: string[] | null;
  firstNameOrLastNameContains?: string | null;
  contactType?: PartyContactType | null;
  isActive?: boolean | null;
  ids?: string[] | null;
}

export interface PartyFilterInput {
  ids?: string[] | null;
  nameContains?: string | null;
  aliasContains?: string | null;
  addressContains?: string | null;
  cityContains?: string | null;
  stateContains?: string | null;
  zipContains?: string | null;
  projectSetupClientCodeContains?: string | null;
  projectSetupClientCodeOrNameContains?: string | null;
  notesContains?: string | null;
  associatedClientsContains?: string | null;
  divisionOwnershipIds?: string[] | null;
  focalPointUserIds?: string[] | null;
  focalPointUserNameContains?: string | null;
  isActive?: boolean | null;
  partyType?: PartyTypeEnum | null;
  partyDesignationIds?: string[] | null;
  partyDesignationId?: string | null;
  countryIds?: string[] | null;
  countryId?: string | null;
  createdAt?: any | null;
  createdAtFrom?: any | null;
  createdAtTo?: any | null;
}

export interface PartySortInput {
  column: PartySortableColumn;
  order: Order;
}

export interface PartyStockExchangeAddInput {
  stockExchangeValue?: number | null;
  stockExchangeSymbol?: string | null;
  stockExchangeId?: number | null;
}

export interface PartyStockExchangeModifyInput {
  partyStockExchangeId: string;
  stockExchangeValue?: number | null;
  stockExchangeSymbol?: string | null;
  stockExchangeId?: number | null;
}

export interface ProjectFilter {
  keyOrNameContains?: string | null;
  keyContains?: string | null;
  nameContains?: string | null;
  isActive?: boolean | null;
  isMoscow?: boolean | null;
  partyIds?: string[] | null;
  contract_containerAssignable?: boolean | null;
  contract_containerId?: number | null;
}

export interface ProjectSort {
  column: ProjectSortableColumn;
  order: Order;
}

export interface StockExchangeFilter {
  withDeleted?: boolean | null;
  nameOrAcronymOrMicContains?: string | null;
  ids?: string[] | null;
}

export interface UploadedFileInput {
  blobContainerName: string;
  blobName: string;
  fileFilename: string;
  fileMimeType: string;
}

export interface UserFilter {
  oid?: string | null;
  nameContains?: string | null;
  emailContains?: string | null;
  nameOrEmailContains?: string | null;
  isActive?: boolean | null;
  isMoscow?: boolean | null;
  isAssetsUser?: boolean | null;
  divisionId?: string | null;
  projectId?: string | null;
}

export interface UserSort {
  column: UserSortableColumns;
  order: Order;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
